import { BaseLink } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import qs from "query-string";
import { useLocation } from "react-router-dom";

import { useReferrals } from "./api/useReferrals";
import { ReferralCardSkeleton } from "./components";
import { WorkplaceReferralsListCard } from "./components/WorkplaceReferralsListCard/WorkplaceReferralsListCard";
import {
  WORKPLACE_AFFILIATE_STATS_SCREEN_NAME,
  WORKPLACE_REFERRAL_STATS_SCREEN_NAME,
} from "./constants";
import { useWorkerToWorkplaceAffiliateExperiment } from "./hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH, WorkplaceReferralRouterPath } from "./paths";
import {
  RefereeType,
  ReferralConversionStage,
  WorkplaceReferralAction,
  WorkplaceReferralComponent,
} from "./types";
import { getWorkerToWorkplaceVariantName } from "./utils/getWorkerToWorkplaceVariantName";

const WORKPLACE_REFERRAL_TABS = [
  { label: "Successful", id: ReferralConversionStage.CONVERTED },
  { label: "Pending", id: ReferralConversionStage.UNCONVERTED },
];
export function WorkplaceReferralsStatsPage() {
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  const worker = useDefinedWorker();

  const { search } = useLocation();

  useLogEffect(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL_STATS, {
    action: WorkplaceReferralAction.PAGE_VIEWED,
    component: WorkplaceReferralComponent.WORKPLACE_REFERRAL_STATS_PAGE,
    variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
    pageQueryParams: qs.parse(search),
  });

  const selectedTab =
    WORKPLACE_REFERRAL_TABS.find(({ id }) => id === qs.parse(search)?.selectedTab) ??
    WORKPLACE_REFERRAL_TABS[0];

  const {
    data: referralStats,
    isLoading: isLoadingReferralStats,
    isSuccess: isReferralStatsSuccess,
  } = useReferrals({
    type: RefereeType.WORKPLACE,
    referrerId: worker.userId,
    conversion: selectedTab.id,
  });

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={
              isWorkerToWorkplaceAffiliateProgramEnabled
                ? WORKPLACE_AFFILIATE_STATS_SCREEN_NAME
                : WORKPLACE_REFERRAL_STATS_SCREEN_NAME
            }
            leftCta={
              <BackButtonLink
                defaultBackTo={WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH}
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL_STATS, {
                    action: WorkplaceReferralAction.BACK_BUTTON_CLICKED,
                    component: WorkplaceReferralComponent.WORKPLACE_REFERRAL_STATS_PAGE,
                    variant: getWorkerToWorkplaceVariantName(
                      isWorkerToWorkplaceAffiliateProgramEnabled
                    ),
                  });
                }}
              />
            }
          />
        }
        containerVariant="without-margin"
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <Tabs
            value={selectedTab.id}
            aria-label="Select referral conversion type tabs"
            onChange={() => {
              logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                action: WorkplaceReferralAction.REFERRAL_STATS_TAB_CLICKED,
                component: WorkplaceReferralComponent.WORKPLACE_REFERRAL_STATS_TAB_ITEM,
                variant: getWorkerToWorkplaceVariantName(
                  isWorkerToWorkplaceAffiliateProgramEnabled
                ),
              });
            }}
          >
            {WORKPLACE_REFERRAL_TABS.map(({ label, id }) => (
              <Tab
                key={id}
                value={id}
                label={label}
                id={`simple-tab-${id}`}
                sx={{ width: "50%" }}
                aria-controls={`simple-tabpanel-${id}`}
                component={BaseLink}
                to={{
                  pathname: WorkplaceReferralRouterPath.WORKPLACE_REFERRALS_STATS_PATH,
                  search: `?selectedTab=${id}`,
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ backgroundColor: (theme) => theme.palette.background.default, height: "100%" }}>
          <Stack
            spacing={1}
            sx={{
              paddingX: 2,
              paddingTop: 2,
            }}
          >
            {isReferralStatsSuccess
              ? referralStats?.data?.map(({ workplace, refereeBonus }) => (
                  <WorkplaceReferralsListCard
                    key={workplace.id}
                    workplaceName={workplace.name}
                    workplaceAddress={workplace.fullAddress.formatted}
                    workplaceUserEmail={workplace.user.email}
                    workplaceUserName={workplace.user.name}
                    {...(selectedTab.id === ReferralConversionStage.CONVERTED
                      ? { bonusAmount: refereeBonus?.amountApplied }
                      : {})}
                  />
                ))
              : null}
          </Stack>
        </Box>

        {isLoadingReferralStats && <ReferralCardSkeleton count={4} height={{ body: 240 }} />}
      </PageWithHeader>
    </IonPage>
  );
}

import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

import {
  type ReferralStatsRequest,
  referralStatsRequestSchema,
  type ReferralStatsResponse,
  referralStatsResponseSchema,
} from "../types";

export const GET_REFERRAL_STATS_PATH = "/referrals/stats";

export function useReferralStats(
  params: ReferralStatsRequest,
  options: UseGetQueryOptions<ReferralStatsResponse> = {}
): UseQueryResult<ReferralStatsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_REFERRAL_STATS_PATH}`,
    queryParams: params,
    requestSchema: referralStatsRequestSchema,
    responseSchema: referralStatsResponseSchema,
    meta: {
      userErrorMessage: "Something went wrong while fetching the referral stats",
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_REFERRAL_STATS_FAILURE,
    },
    ...options,
  });
}

import { get, post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import {
  type ReferralCodeResponse,
  referralCodeResponseSchema,
  referralCodesResponseSchema,
} from "../types";
import { getReferralCodePath } from "./utils";

export function useReferralCode(
  params: { workerId: string },
  options: UseQueryOptions<ReferralCodeResponse> = {}
): UseQueryResult<ReferralCodeResponse> {
  const { workerId } = params;
  const referralCodeUrl = `${environmentConfig.REACT_APP_BASE_API_URL}${getReferralCodePath(
    workerId
  )}`;
  return useQuery({
    queryKey: [getReferralCodePath(workerId)],
    queryFn: async () => {
      const getResponse = await get({
        url: referralCodeUrl,
        responseSchema: referralCodesResponseSchema,
      });
      if (getResponse.data.referralCodes.length > 0) {
        return getResponse.data;
      }

      const createdResponse = await post({
        url: referralCodeUrl,
        responseSchema: referralCodeResponseSchema,
      });
      return { referralCodes: [createdResponse.data] };
    },
    ...options,
  });
}

import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

import {
  type ReferralsRequest,
  referralsRequestSchema,
  type ReferralsResponse,
  referralsResponseSchema,
} from "../types";

export const GET_REFERRALS_PATH = "/referrals";

export function useReferrals(
  params: ReferralsRequest,
  options: UseGetQueryOptions<ReferralsResponse> = {}
): UseQueryResult<ReferralsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_REFERRALS_PATH}`,
    queryParams: params,
    requestSchema: referralsRequestSchema,
    responseSchema: referralsResponseSchema,
    meta: {
      userErrorMessage: "Something went wrong while fetching referrals",
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_REFERRAL_STATS_FAILURE,
    },
    ...options,
  });
}

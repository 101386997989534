import { type ApiResponse, post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const shareYourProfileRequestSchema = z.object({
  workplace: z.object({
    email: z.string(),
  }),
});

const shareYourProfileResponseSchema = z.object({
  success: z.boolean(),
});

export type ShareYourProfileRequest = z.infer<typeof shareYourProfileRequestSchema>;
export type ShareYourProfileResponse = z.infer<typeof shareYourProfileResponseSchema>;

export function getShareYourProfileUrl(agentId: string, referralCode: string) {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${agentId}/referral-codes/${referralCode}/notifications`;
}

export function useShareYourProfile(
  params: { agentId: string; referralCode: string },
  options: UseMutationOptions<
    ApiResponse<ShareYourProfileResponse>,
    AxiosError,
    ShareYourProfileRequest
  > = {}
): UseMutationResult<ApiResponse<ShareYourProfileResponse>, AxiosError, ShareYourProfileRequest> {
  const { agentId, referralCode } = params;
  return useMutation({
    mutationFn: async (data: ShareYourProfileRequest) => {
      return await post({
        url: getShareYourProfileUrl(agentId, referralCode),
        data,
        requestSchema: shareYourProfileRequestSchema,
        responseSchema: shareYourProfileResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_SHARE_YOUR_PROFILE_FAILURE,
      userSuccessMessage: "Shared your profile successfully",
      userErrorMessage: "Could not share your profile!",
    },
    ...options,
  });
}
